import client from "../api-config";

export function getBoards() {
  return client.get("/api/boards");
}

export function getClasses() {
  return client.get("/api/classes");
}

export function getSubjects() {
  return client.get("/api/subjects");
}

export function getFeedbacks() {
  return client.get("/api/feedbacks");
}

// chapters
export function getChapter(id) {
  return client.get(`/api/chapter/${id}`);
}

export function getChapters() {
  return client.get("/api/chapters");
}

export function createChapter(body) {
  return client.post("/api/chapter/add", body);
}

export function updateChapter(data) {
  return client.put(`/api/chapters/${data.id}`, { ...data.body });
}

export function reviewChapter(data) {
  return client.put(`/api/chapter/review/${data.id}`, { ...data.body });
}

export function deleteChapter(id) {
  return client.delete(`api/chapters/${id}`);
}

// topics
export function getTopic(id) {
  return client.get(`/api/topic/${id}`);
}

export function getTopics() {
  return client.get("/api/topics");
}

export function createTopic(body) {
  return client.post("/api/topic/add", body);
}

export function updateTopic(data) {
  return client.put(`/api/topic/${data.id}`, { ...data.body });
}

export function reviewTopic(data) {
  return client.put(`/api/topic/review/${data.id}`, { ...data.body });
}

export function deleteAssignTopic(data) {
  return client.put(`/api/topic/deleteAssign/${data.id}`, { ...data.body });
}

export function deleteTopic(id) {
  return client.delete(`/api/topic/${id}`);
}

// questions
export function getQuestion(id) {
  return client.get(`/api/question/${id}`);
}

export function getQuestions() {
  return client.get("/api/questions");
}

export function createQuestion(body) {
  return client.post("/api/question/add", body);
}

export function updateQuestion(data) {
  return client.put(`/api/question/${data.id}`, data.body);
}

export function reviewQuestion(data) {
  return client.put(`/api/question/review/${data.id}`, { ...data.body });
}

export function deleteQuestion(id) {
  return client.delete(`/api/question/${id}`);
}

// mcqs
export function getMCQ(id) {
  return client.get(`/api/mcq/${id}`);
}

export function getMCQS() {
  return client.get("/api/mcqs");
}

export function createMCQS(body) {
  return client.post("/api/mcqs/add", body);
}

export function updateMCQS(data) {
  return client.put(`/api/mcqs/${data.id}`, data.body);
}

export function reviewMCQ(data) {
  return client.put(`/api/mcq/review/${data.id}`, { ...data.body });
}

export function deleteMCQS(id) {
  return client.delete(`/api/mcqs/${id}`);
}
