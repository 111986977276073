import React from "react";

// components
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";

// api
import { useGetBoards, useGetClasses, useGetSubjects } from "hooks/api/subject";

// packages
import _ from "lodash";
import { useGetPaperSessions, useGetPaperTypes } from "hooks/api/paper";
import { CustomCheckbox } from "./CustomComponent";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const PaperDetail = ({
  formState,
  control,
  randomSelection,
  setRandomSelection,
}) => {
  const { data: paperSessions } = useGetPaperSessions();
  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: subjects } = useGetSubjects();
  const { data: paperTypes } = useGetPaperTypes();

  return (
    <>
      <div className="card">
        <div className="header">
          <h2>Paper Detail</h2>
        </div>

        <div className="body">
          <div className="row clearfix">
            <FormSelect
              control={control}
              error={formState?.errors?.board_id}
              item={{
                name: "sessionId",
                label: "Sessions",
                options: paperSessions?.data?.map((ele) => ({
                  value: ele.id,
                  label: ele.session,
                })),
                rules,
              }}
            />

            <FormTextField
              control={control}
              error={formState?.errors?.paper_title}
              item={{
                name: "paper_title",
                label: "Paper Title",
                placeholder: "Enter Paper Title",
                rules,
              }}
            />

            <FormSelect
              control={control}
              error={formState?.errors?.board_id}
              item={{
                name: "board_id",
                label: "Board",
                options: boards?.data?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                })),
                rules,
              }}
            />
            <FormSelect
              control={control}
              error={formState?.errors?.class_id}
              item={{
                name: "class_id",
                label: "Class",
                options: classes?.data?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                })),
                rules,
              }}
            />
            <FormSelect
              control={control}
              error={formState?.errors?.subject_id}
              item={{
                name: "subject_id",
                label: "Subject",
                options: subjects?.data?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                })),
                rules,
              }}
            />

            <FormSelect
              control={control}
              error={formState?.errors?.paper_type_id}
              item={{
                name: "paper_type_id",
                label: "Paper Type",
                options: paperTypes?.data?.map((ele) => ({
                  value: ele.id,
                  label: ele.type_name,
                })),
                rules,
              }}
            />

            <FormTextField
              control={control}
              error={formState?.errors?.paper_time}
              item={{
                name: "paper_time",
                label: "Paper Time",
                placeholder: "Enter Paper Time",
                rules,
              }}
            />
            <FormTextField
              control={control}
              error={formState?.errors?.paper_marks}
              item={{
                name: "paper_marks",
                label: "Paper Marks",
                placeholder: "Enter Paper Marks",
                rules,
              }}
            />

            <FormTextField
              control={control}
              error={formState?.errors?.paperDate}
              item={{
                name: "paperDate",
                label: "Paper Date",
                type: "date",
                placeholder: "Enter Paper Date",
                rules,
              }}
            />

            <div className="col-6">
              <CustomCheckbox
                label="Automatic Paper Generation"
                defaultChecked={false}
                value={!!randomSelection?.all}
                onChange={(e) =>
                  setRandomSelection({
                    all: e.target.checked,
                    mcqs: e.target.checked,
                    shortQuestions: e.target.checked,
                    longQuestions: e.target.checked,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="header">
          <h2>MCQS Detail</h2>
        </div>

        <div className="body">
          <div className="row clearfix">
            <FormTextField
              control={control}
              error={formState?.errors?.objective_note}
              item={{
                name: "objective_note",
                label: "MCQS Statement",
                placeholder: "Enter note",
              }}
            />

            <FormTextField
              control={control}
              error={formState?.errors?.objective_marks}
              item={{
                name: "objective_marks",
                label: "Marks",
                placeholder: "Enter marks",
              }}
            />

            <FormTextField
              control={control}
              error={formState?.errors?.objective_time}
              item={{
                name: "objective_time",
                label: "Time",
                placeholder: "Enter Time",
              }}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="header">
          <h2>Question Detail</h2>
        </div>

        <div className="body">
          <div className="row clearfix">
            <FormTextField
              control={control}
              error={formState?.errors?.subjective_marks}
              item={{
                name: "subjective_marks",
                label: "Marks",
                placeholder: "Enter marks",
              }}
            />

            <FormTextField
              control={control}
              error={formState?.errors?.subjective_time}
              item={{
                name: "subjective_time",
                label: "Time",
                placeholder: "Enter Time",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaperDetail;
