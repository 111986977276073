import React from "react";

// third party
import { Controller } from "react-hook-form";

export default function FormSelect({ control, error, item }) {
  return (
    <Controller
      control={control}
      name={item.name}
      defaultValue={item?.defaultValue || ""}
      rules={item.rules}
      render={({ field }) => (
        <div className="col-6 mb-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <label className="input-group-text">{item.label}</label>
            </div>
            <select
              disabled={!!item?.disabled}
              {...field}
              className="custom-select"
            >
              <option disabled selected value="">
                -- select an option --
              </option>
              {item.options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {error?.message && (
            <ul className="parsley-errors-list filled">
              <li className="parsley-required">{error?.message}</li>
            </ul>
          )}
        </div>
      )}
    />
  );
}
