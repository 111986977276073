// third party
import { useQuery, useMutation } from "@tanstack/react-query";

// hooks
import {
  getBoards,
  getClasses,
  getSubjects,
  getChapters,
  createChapter,
  deleteChapter,
  getTopics,
  createTopic,
  deleteTopic,
  getQuestions,
  createQuestion,
  deleteQuestion,
  getMCQS,
  createMCQS,
  deleteMCQS,
  getChapter,
  getTopic,
  getQuestion,
  getMCQ,
  updateChapter,
  updateTopic,
  updateQuestion,
  updateMCQS,
  reviewChapter,
  reviewTopic,
  reviewQuestion,
  reviewMCQ,
  getFeedbacks,
} from "api/v1/subject";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useGetBoards = () => {
  return useQuery({
    queryKey: ["boards"],
    queryFn: () => getBoards(),
    refetchOnWindowFocus: false,
  });
};

export const useGetClasses = () => {
  return useQuery({
    queryKey: ["classes"],
    queryFn: () => getClasses(),
    refetchOnWindowFocus: false,
  });
};

export const useGetSubjects = () => {
  return useQuery({
    queryKey: ["subjects"],
    queryFn: () => getSubjects(),
    refetchOnWindowFocus: false,
  });
};

export const useGetFeedbacks = () => {
  return useQuery({
    queryKey: ["feedbacks"],
    queryFn: () => getFeedbacks(),
    refetchOnWindowFocus: false,
  });
};

// chapters
export const useGetChapter = (id) => {
  return useQuery({
    queryKey: ["chapters", id],
    queryFn: () => getChapter(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useGetChapters = () => {
  return useQuery({
    queryKey: ["chapters"],
    queryFn: () => getChapters(),
    refetchOnWindowFocus: false,
  });
};

export const useCreateChapter = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createChapter,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useUpdateChapter = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateChapter,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useReviewChapter = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: reviewChapter,
    onSuccess: () => toasterSuccess("Saved!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteChapter = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteChapter,
    onSuccess: () => {
      refetch();
      toasterSuccess("Chapter Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

// topics
export const useGetTopic = (id) => {
  return useQuery({
    queryKey: ["topics", id],
    queryFn: () => getTopic(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useGetTopics = () => {
  return useQuery({
    queryKey: ["topics"],
    queryFn: () => getTopics(),
    refetchOnWindowFocus: false,
  });
};

export const useCreateTopic = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createTopic,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useUpdateTopic = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateTopic,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useReviewTopic = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: reviewTopic,
    onSuccess: () => toasterSuccess("Saved!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteTopic = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteTopic,
    onSuccess: () => {
      refetch();
      toasterSuccess("Topic Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

// questions
export const useGetQuestion = (id) => {
  return useQuery({
    queryKey: ["questions", id],
    queryFn: () => getQuestion(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useGetQuestions = () => {
  return useQuery({
    queryKey: ["questions"],
    queryFn: () => getQuestions(),
    refetchOnWindowFocus: false,
  });
};

export const useCreateQuestion = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createQuestion,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useUpdateQuestion = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateQuestion,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useReviewQuestion = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: reviewQuestion,
    onSuccess: () => toasterSuccess("Saved!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteQuestion = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteQuestion,
    onSuccess: () => {
      refetch();
      toasterSuccess("Question Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

// mcqs
export const useGetMCQ = (id) => {
  return useQuery({
    queryKey: ["mcqs", id],
    queryFn: () => getMCQ(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useGetMCQS = () => {
  return useQuery({
    queryKey: ["mcqs"],
    queryFn: () => getMCQS(),
    refetchOnWindowFocus: false,
  });
};

export const useCreateMCQS = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createMCQS,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useUpdateMCQS = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateMCQS,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useReviewMCQS = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: reviewMCQ,
    onSuccess: () => toasterSuccess("Saved!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteMCQS = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteMCQS,
    onSuccess: () => {
      refetch();
      toasterSuccess("MCQS Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};
